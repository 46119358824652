<template>
  <div class="container">
    <template v-if="!isCard">
      <h3>
        {{
          payWay === "mycard2" || payWay === "gash2"
            ? $t("myCard.titlePayList")
            : $t("myCard.titlePayAccount")
        }}
      </h3>
      <div v-if="giftList.length === 0 && btnDisabled" class="empty">
        <img src="@/assets/images/mycardH5/empty.png" alt="" />
        <p>{{ $t("myCard.empty") }}</p>
      </div>
      <div v-else v-for="(each, index3) in giftList" :key="index3">
        <h3 class="sec-title">{{ each.title }}</h3>
        <div class="flex" style="justify-content: left">
          <template v-if="!isKr">
            <span
              v-for="(item, index4) in each.arr"
              :key="index4"
              :class="[
                'check',
                'card',
                item.show === 0 ||
                (item.expiration_time && item.expiration_time < 0)
                  ? 'disable'
                  : '',
                chooseProduct.product_id === item.product_id ? 'active' : '',
              ]"
              @click="chooseGift(item)"
            >
              <span class="flex">
                <span class="extra" v-if="item.extra_num && item.extra_num > 0"
                  >{{ $t("myCard.extraGift") }}: {{ item.extra_num }}</span
                >
                <span
                  class="stock"
                  v-if="item.current_stock && item.current_stock > 0"
                  >{{ $t("myCard.stock")
                  }}{{
                    item.current_stock && item.current_stock > 99
                      ? "99+"
                      : item.current_stock
                  }}</span
                >
              </span>
              <span class="img">
                <img
                  class="bg"
                  src="@/assets/images/mycard/gift_bg.png"
                  alt=""
                />
                <img
                  v-if="item.icon && item.icon != null"
                  class="gift"
                  :src="
                    'https://oversea-update.fungoglobal.com/cat_web/goods_icon_' +
                    item.icon +
                    '.png'
                  "
                  alt=""
                />
                <img
                  v-else
                  class="gift"
                  src="@/assets/images/mycard/gift_default.png"
                  alt=""
                />
              </span>
              <span class="title">{{ item.product_subject }}</span>
              <span class="price"
                >{{ item.currency_code }}{{ item.price / 100 }}</span
              >
              <i v-if="item.expiration_time" class="time">{{
                item.expiration_time > 0
                  ? $t("myCard.time") + ": " + formatTime(item)
                  : $t("myCard.timeout")
              }}</i>
            </span>
          </template>
          <template v-else>
            <span
              v-for="(item, index4) in each.arr"
              :key="index4"
              :class="[
                'check',
                'card',
                'kr-card',
                item.show === 0 ||
                (item.expiration_time && item.expiration_time < 0)
                  ? 'disable'
                  : '',
                chooseProduct.product_id === item.product_id && !pointNum
                  ? 'active'
                  : '',
              ]"
              @click="chooseGift(item)"
            >
              <span class="flex">
                <span class="extra_gift_percent" v-if="item.web_icon">
                  <img
                    :src="
                      'https://kr-update.fungoglobal.com/cat_web/' +
                      item.web_icon +
                      '.png'
                    "
                    alt=""
                  />
                </span>

                <span class="extra" v-if="item.extra_num && item.extra_num > 0"
                  >{{ $t("myCard.extraGift") }}: {{ item.extra_num }}</span
                >
                <span
                  class="stock"
                  v-if="item.current_stock && item.current_stock > 0"
                  >{{ $t("myCard.stock")
                  }}{{
                    item.current_stock && item.current_stock > 99
                      ? "99+"
                      : item.current_stock
                  }}</span
                >
              </span>
              <span class="img">
                <img
                  class="bg"
                  src="@/assets/images/mycard/gift_bg.png"
                  alt=""
                />
                <img
                  v-if="item.product_id && item.product_id != null"
                  class="gift"
                  :src="
                    'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                    item.product_id +
                    '.png?2024'
                  "
                  alt=""
                />
                <img
                  v-else
                  class="gift"
                  src="@/assets/images/mycard/gift_default.png"
                  alt=""
                />
                <span class="kr-double" v-if="item.is_first === 1"
                  ><img src="@/assets/images/mycard/kr-double.png" alt=""
                /></span>
              </span>
              <span class="title"
                >{{ item.product_subject
                }}{{ item.num ? "x" + item.num : "" }}</span
              >
              <span
                class="kr_extra"
                v-if="item.first_extra_num || item.extra_goods_num"
              >
                <span>{{ $t("myCard.extraSend") }}</span>
                <img
                  :src="
                    'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                    item.extra_goods_id +
                    '.png'
                  "
                  alt=""
                />
                <span
                  >x{{
                    item.is_first === 1
                      ? item.first_extra_num
                      : item.extra_goods_num
                  }}</span
                >
                <template
                  v-if="
                    item.web_goods_num &&
                    item.web_goods_id !== item.extra_goods_id
                  "
                >
                  <img
                    :src="
                      'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                      item.web_goods_id +
                      '.png'
                    "
                    alt=""
                  />
                  <span>x{{ item.web_goods_num }}</span>
                </template>
              </span>
              <span class="price"
                >{{ item.currency_code }}{{ item.price / 100 }}</span
              >
              <i v-if="item.expiration_time" class="time">{{
                item.expiration_time > 0
                  ? $t("myCard.time") + ": " + formatTime(item)
                  : $t("myCard.timeout")
              }}</i>
            </span>
          </template>

          <!--       自定义     -->
          <span
            v-if="isKr && hasCustom(each.arr)"
            :class="['check', 'card', 'kr-card', pointNum ? 'active' : '']"
            @click="setPoint()"
          >
            <span class="extra_gift_percent">
              <span>+ {{ pointCustom.addition_num * 100 }}%</span>
            </span>
            <span class="img">
              <img class="bg" src="@/assets/images/mycard/gift_bg.png" alt="" />
              <img
                class="gift"
                src="https://kr-update.fungoglobal.com/cat_web/goods_icon_custom3000001.png"
                alt=""
              />
            </span>
            <span class="title">{{ $t("myCard.extraTitle") }}</span>
            <span class="kr_extra">
              <template v-if="pointNum">
                <img
                  src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                  alt=""
                />
                <em>x{{ pointNum }}</em>
                <template v-if="pointExtraNum">
                  <img
                    src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                    alt=""
                  />
                  <em>x{{ pointExtraNum }}</em>
                </template>
              </template>
              <template v-else>
                <img
                  src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                  alt=""
                />
                <em>{{ $t("myCard.otherNum") }}</em>
              </template>
            </span>
            <span class="price"
              >{{ pointCustom.currency_code
              }}{{ pointPrice ? pointPrice : "_" }}</span
            >
          </span>
        </div>
      </div>
    </template>
    <h3 ref="h3">{{ $t("myCard.titlePayWay") }}</h3>
    <div :class="['pay-type', isEn ? 'en' : '']">
      <span
        v-for="(item, index1) in payWays"
        :key="index1"
        :class="payWay === item.name ? 'active' : ''"
        @click="choosePayWay(item)"
      >
        <img
          v-if="item.type === '11'"
          src="@/assets/images/mycard/mycard.png"
          alt=""
        />
        <img
          v-if="item.type === '12'"
          src="@/assets/images/mycard/gash.png"
          alt=""
        />
        <img
          v-if="item.type === '15'"
          src="@/assets/images/mycard/paypal.jpeg"
          alt=""
        />
        <img
          v-if="item.type === '22'"
          src="@/assets/images/mycard/payletter.png"
          alt=""
        />
        <span class="name" v-if="item.text">
          <img src="@/assets/images/mycard/pay_name.png" alt="" />
          <i>{{ item.text }}</i>
        </span>
      </span>
    </div>
    <div class="pay-letter" v-if="payWay === 'payletter'">
      <span
        v-for="(item, index4) in payLetterList"
        :key="index4"
        :class="[
          pg_code === item.id ? 'active' : '',
          item.id === 'creditcard' ? 'creditcard' : '',
        ]"
        @click="pg_code = item.id"
      >
        <i></i>
        <em v-if="item.id === 'creditcard'">신용카드/체크카드</em>
        <img :src="item.img" alt="" />
      </span>
    </div>
    <template v-if="isCard">
      <h3>
        {{
          payWay === "mycard2" || payWay === "gash2"
            ? $t("myCard.titlePayList")
            : $t("myCard.titlePayAccount")
        }}
      </h3>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th>{{ $t("myCard.myCardPoint") }}</th>
              <th>{{ $t("myCard.myCardGiftNum") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index2) in pointList" :key="index2">
              <td>{{ item.point }}{{ $t("myCard.point") }}</td>
              <td>{{ item.num }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pay-card">
          <div class="flex">
            <span>{{ $t("myCard.cardNum") }}：</span>
            <input
              type="text"
              v-model="cardId"
              :placeholder="$t('myCard.placeholderCardNum')"
            />
          </div>
          <div class="flex">
            <span>{{ $t("myCard.cardPass") }}：</span>
            <input
              type="text"
              v-model="cardPw"
              :placeholder="$t('myCard.placeholderCardPass')"
            />
          </div>
        </div>
      </div>
      <a
        :class="['login-btn', disabled ? 'disabled' : '']"
        v-if="btnDisabled"
        @click="showLogin"
        >{{
          payWay === "mycard2" || payWay === "gash2"
            ? $t("myCard.change")
            : $t("myCard.submit")
        }}</a
      >
      <a
        :class="['login-btn', disabled ? 'disabled' : '']"
        v-else
        @click="submitCard()"
        >{{
          payWay === "mycard2" || payWay === "gash2"
            ? $t("myCard.change")
            : $t("myCard.submit")
        }}</a
      >
    </template>

    <h3 class="sec-title" v-if="!isCard">
      {{ $t("myCard.chooseCoupon") }}
      <span class="coupon-btn" @click="couponExchange">{{
        $t("myCard.exchangeCoupon")
      }}</span>
    </h3>
    <div class="coupon" v-if="!isCard">
      <p v-if="btnDisabled">{{ $t("myCard.loginTips") }}</p>
      <template v-else>
        <p v-if="!couponList.length">{{ $t("myCard.noCoupon") }}</p>
        <div v-else class="coupon-list">
          <div
            :class="[
              'each',
              'flex',
              item.disabled ? 'disabled' : '',
              item.active === 1 ? 'active' : '',
            ]"
            v-for="(item, index) in couponList"
            :key="index"
            @click="chooseCoupon(item)"
          >
            <p
              v-if="
                item.max_discount_price &&
                item.max_discount_price[couponCurrentCode]
              "
            >
              {{ $t("myCard.maxNum") }}
              {{ couponCurrentCode === "KRW" ? "₩" : couponCurrentCode
              }}{{ item.max_discount_price[couponCurrentCode] }}
            </p>
            <div>
              <div class="c-name">
                <span>{{ item.name }}</span>
                <div class="c-question" @click.stop="showCouponInfo(item)">
                  <img
                    src="@/assets/images/mycard/question-circle.png"
                    alt=""
                  />
                </div>
              </div>
              <p
                v-if="
                  item.price_condition &&
                  item.price_condition[couponCurrentCode]
                "
                class="min-num"
              >
                {{
                  $t("myCard.useType", {
                    amount:
                      couponCurrentCode === "KRW"
                        ? "₩" + item.price_condition[couponCurrentCode]
                        : couponCurrentCode +
                          item.price_condition[couponCurrentCode],
                  })
                }}
              </p>
              <p class="time">
                {{ $t("myCard.expireAt")
                }}{{ formatDate(item.expire_at, "yyyy-MM-dd hh:mm:ss") }}
              </p>
            </div>
            <div class="c-num">
              <p>
                <em
                  class="share-tag"
                  v-if="item.can_share === 1"
                  >{{ $t("myCard.share") }}</em
                >
                <i>{{ 100 - item.discount }}%</i>{{ $t("myCard.disCount") }}
              </p>
            </div>
          </div>
        </div>
        <div class="c-info" v-if="showInfo">
          <div class="c-main">
            <div class="c-close" @click="showInfo = false">
              <img src="@/assets/images/close.png" alt="" />
            </div>
            <div class="z-title">{{ chooseQuestion.name }}</div>
            <div class="c-content" v-html="chooseQuestion.content"></div>
          </div>
        </div>
      </template>

      <div class="choose-item-info flex">
        <span v-if="pointPrice">
          <img class="bg" src="@/assets/images/mycard/gift_bg.png" alt="" />
          <img
            src="https://kr-update.fungoglobal.com/cat_web/goods_icon_custom3000001.png"
            alt=""
          />
          <span class="extra_gift_percent">
            <span>+ {{ pointCustom.addition_num * 100 }}%</span>
          </span>
        </span>
        <span v-else-if="!pointPrice && chooseProduct.price">
          <img class="bg" src="@/assets/images/mycard/gift_bg.png" alt="" />
          <template v-if="isKr">
            <img
              v-if="chooseProduct.product_id"
              :src="
                'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                chooseProduct.product_id +
                '.png?2024'
              "
              alt=""
            />
            <img
              v-else
              class="gift"
              src="@/assets/images/mycard/gift_default.png"
              alt=""
            />
          </template>
          <template v-else>
            <img
              v-if="chooseProduct.icon"
              :src="
                'https://oversea-update.fungoglobal.com/cat_web/goods_icon_' +
                chooseProduct.icon +
                '.png'
              "
              alt=""
            />
            <img
              v-else
              class="gift"
              src="@/assets/images/mycard/gift_default.png"
              alt=""
            />
          </template>

          <span class="extra_gift_percent" v-if="chooseProduct.web_icon">
            <img
              :src="
                'https://kr-update.fungoglobal.com/cat_web/' +
                chooseProduct.web_icon +
                '.png'
              "
              alt=""
            />
          </span>
        </span>
        <i v-else></i>
        <div>
          <p>
            {{ $t("myCard.proInfo") }}
            <span v-if="pointNum">{{ $t("myCard.extraTitle") }}</span>
            <span v-else-if="chooseProduct.product_subject">{{
              chooseProduct.product_subject +
              (chooseProduct.num ? "x" + chooseProduct.num : "")
            }}</span>
            <span v-else>-</span>
          </p>
          <!--          <p>-->
          <!--            {{$t("myCard.proNum")}} <span>{{-->
          <!--              pointNum ? pointNum : chooseProduct.price ? 1 : "-"-->
          <!--            }}</span>-->
          <!--          </p>-->
          <p>
            {{ $t("myCard.roleInfo") }}
            <span>{{
              chooseRoleInfo && chooseRoleInfo.server
                ? chooseRoleInfo.server + " - " + chooseRoleInfo.role_name
                : "-"
            }}</span>
          </p>
        </div>
        <div class="line">
          <p>
            {{ $t("myCard.proPrice") }}
            <template v-if="pointPrice || chooseProduct.price">
              <span
                >{{
                  pointCustom.currency_code || chooseProduct.currency_code || ""
                }}
                {{
                  pointPrice
                    ? pointPrice
                    : chooseProduct.price
                    ? (chooseProduct.price / 100).toFixed(2)
                    : 0
                }}</span
              >
            </template>
            <span v-else>-</span>
          </p>
          <p>
            {{ $t("myCard.disCountPrice") }}

            <span v-if="hasChooseCouponList.length > 0">
              -
              {{
                pointCustom.currency_code || chooseProduct.currency_code || ""
              }}
              {{ disCountPrice }}</span
            >
            <span v-else>-</span>
          </p>
        </div>
      </div>
    </div>
    <h3>
      {{ $t("payRule.title") }}
    </h3>
    <div class="pay_tips">
      <p>{{ $t("payRule.text1") }}</p>
      <p>{{ $t("payRule.text2") }}</p>
      <p>{{ $t("payRule.text3") }}</p>
      <p>{{ $t("payRule.text4") }}</p>
      <p>{{ $t("payRule.text5") }}</p>
      <p>{{ $t("payRule.text6") }}</p>
    </div>
    <div class="fix" v-if="!isCard">
      <div class="flex">
        <div class="left">
          <span>
            <span>{{ $t("myCard.proPrice") }}</span>
            <template v-if="pointPrice || chooseProduct.price">
              <em
                >{{
                  pointCustom.currency_code || chooseProduct.currency_code || ""
                }}
                {{
                  pointPrice
                    ? pointPrice
                    : chooseProduct.price
                    ? (chooseProduct.price / 100).toFixed(2)
                    : 0
                }}</em
              >
            </template>
            <span v-else>-</span>
          </span>
          <span>
            <span>{{ $t("myCard.disCountPrice") }}</span>

            <em v-if="hasChooseCouponList.length > 0">
              -
              {{
                pointCustom.currency_code || chooseProduct.currency_code || ""
              }}
              {{ disCountPrice }}</em
            >
            <span v-else>-</span>
          </span>
          <span>
            <span>{{ $t("myCard.totalPrice") }}</span>
            <i v-if="hasChooseCouponList.length > 0"
              >{{
                pointCustom.currency_code || chooseProduct.currency_code || ""
              }}
              {{
                pointPrice
                  ? pointPrice - disCountPrice >
                    minPrices[pointCustom.currency_code_city] / 100
                    ? (pointPrice - disCountPrice).toFixed(2)
                    : minPrices[pointCustom.currency_code_city] / 100
                  : chooseProduct.price
                  ? chooseProduct.price / 100 - disCountPrice >
                    minPrices[chooseProduct.currency_code_city] / 100
                    ? (chooseProduct.price / 100 - disCountPrice).toFixed(2)
                    : minPrices[chooseProduct.currency_code_city] / 100
                  : 0
              }}
            </i>
            <i v-else-if="pointPrice || chooseProduct.price"
              >{{
                pointCustom.currency_code || chooseProduct.currency_code || ""
              }}
              {{
                pointPrice
                  ? pointPrice
                  : chooseProduct.price
                  ? (chooseProduct.price / 100).toFixed(2)
                  : "-"
              }}</i
            >
            <i v-else>-</i>
          </span>
        </div>
        <div class="right">
          <div class="">
            <p>{{ $t("myCard.titlePayWay") }}:</p>
            <template>
              <img
                v-if="payWayId === '11'"
                src="@/assets/images/mycard/mycard.png"
                alt=""
              />
              <img
                v-if="payWayId === '12'"
                src="@/assets/images/mycard/gash.png"
                alt=""
              />
              <img
                v-if="payWayId === '15'"
                src="@/assets/images/mycard/paypal.jpeg"
                alt=""
              />
              <template v-if="payWayId === '22'">
                <img
                  v-if="pg_code === 'naverpay'"
                  src="@/assets/images/mycard/naverpay.png"
                  alt=""
                />
                <img
                  v-if="pg_code === 'payco'"
                  src="@/assets/images/mycard/payco.png"
                  alt=""
                />
                <img
                  v-if="pg_code === 'kakaopay'"
                  src="@/assets/images/mycard/opay.png"
                  alt=""
                />
                <img
                  v-if="pg_code === 'creditcard'"
                  src="@/assets/images/mycard/creditcard-mini.png"
                  alt=""
                />
              </template>
            </template>
          </div>

          <a
            :class="['login-btn', disabled ? 'disabled' : '']"
            v-if="btnDisabled"
            @click="showLogin"
            >{{
              payWay === "mycard2" || payWay === "gash2"
                ? $t("myCard.change")
                : $t("myCard.submit")
            }}</a
          >
          <a
            :class="['login-btn', disabled ? 'disabled' : '']"
            v-else
            @click="
              payWay === 'mycard2' || payWay === 'gash2'
                ? submitCard()
                : submit()
            "
            >{{
              payWay === "mycard2" || payWay === "gash2"
                ? $t("myCard.change")
                : $t("myCard.submit")
            }}</a
          >
        </div>
      </div>
    </div>
    <!--    -->
    <toast :message="message" v-if="message.length > 0"></toast>
    <loading v-if="showLoading"></loading>
    <div v-if="showPayStatus" class="fix-bg">
      <div class="choose-status" v-if="payStatus === -99">
        <p>{{ $t("myCard.pay1") }}</p>
        <div class="flex">
          <span @click="closeStatus">{{ $t("toast.comfirmBtn4") }}</span>
          <span class="active" @click="payQuery">{{ $t("myCard.pay2") }}</span>
        </div>
      </div>
      <template v-else>
        <div class="success" v-if="payStatus === 2 || payStatus === 4">
          <img src="@/assets/images/mycard/success.png" alt="" />
          <h4>{{ $t("myCard.pay2") }}</h4>
          <p>{{ $t("myCard.pay5") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
        <div class="fail" v-else>
          <img src="@/assets/images/mycard/fail.png" alt="" />
          <h4>{{ $t("myCard.pay3") }}</h4>
          <p>{{ $t("myCard.pay4") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
      </template>
    </div>

    <div class="full" v-show="showAds && adsList.length > 0">
      <div class="full_main">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in adsList"
              :key="index"
            >
              <img :src="item.url" alt="" class="full_bg" />
            </div>
          </div>
          <div class="swiper-pagination" v-if="adsList.length > 1"></div>
        </div>

        <img
          src="@/assets/images/mycard/ad-close.png"
          alt=""
          class="full_close"
          @click="closeAds"
        />
      </div>
    </div>

    <!--  自定义弹窗  -->
    <div class="point-modal" v-show="showPoints">
      <div class="point-main">
        <div class="point-close" @click="closePointModal">
          <img src="@/assets/images/close.png" alt="" />
        </div>
        <div class="point-title">{{ $t("myCard.inputNum") }}</div>
        <input
          type="text"
          :placeholder="$t('myCard.inputNum')"
          v-model.number="pointNum"
          v-on:input="inputPoint"
          oninput="value=value.replace(/[^0-9.]/g,'')"
        />
        <p :class="pointError ? 'error' : ''">
          {{ $t("myCard.extraTips") }}
        </p>
        <div class="point-btn" @click="submitPoint">
          {{ $t("toast.comfirmBtn2") }}
        </div>
      </div>
    </div>

    <!--  冻结优惠券  -->
    <coupon-modal
      v-show="showCouponModal"
      :coupon-order="couponOrder"
      @closeCouponModal="closeCouponModal"
    ></coupon-modal>

    <!--  兑换优惠券  -->
    <exchange
      v-if="showExchange"
      :couponCurrentCode="couponCurrentCode"
      @closeExchangeModal="closeExchangeModal"
      @showCouponInfo="showCouponInfo"
    ></exchange>
  </div>
</template>

<script>
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
} from "@/utils/stage";
import {
  myCardInGamePay,
  myCardWebOrder,
  webItemList,
  gashCommonOrder,
  gashPointOrder,
  payPalWebOrder,
  payQuery,
  payLetterOrder,
  payPalCustom,
  payLetterCustom,
  myCoupon,
  queryCouponOrder,
} from "@/api/mycard";
import toast from "./toast";
import loading from "./loading";
import staticGiftList from "./giftList";
import Swiper from "swiper";
import CouponModal from "@/views/MyCard/components/couponModal";
import Exchange from "@/views/MyCard/components/exchange";
import "@/assets/css/payGift.scss";

export default {
  name: "payCard",
  components: {
    toast,
    loading,
    CouponModal,
    Exchange,
  },
  props: {
    btnDisabled: {
      default: false,
    },
    chooseRoleInfo: {},
  },
  computed: {
    disabled() {
      if (!this.btnDisabled) {
        if (this.payWay === "mycard2" || this.payWay === "gash2") {
          return (
            !(this.cardId.length > 0 && this.cardPw.length > 0) ||
            !this.chooseRoleInfo.role_id
          );
        } else {
          return (
            (!this.chooseProduct.product_id && !this.pointNum) ||
            !this.chooseRoleInfo.role_id
          );
          // && this.payWay === "mycard1"
        }
      } else {
        if (this.payWay === "mycard2" || this.payWay === "gash2") {
          return !(this.cardId.length > 0 && this.cardPw.length > 0);
        } else {
          return !this.chooseProduct.product_id && !this.pointNum;
          // && this.payWay === "mycard1"
        }
      }
    },
    isCard() {
      return this.payWay === "mycard2" || this.payWay === "gash2";
    },
    disCountPrice() {
      let price = "";
      this.hasChooseCouponList.forEach((item) => {
        let maxNum = "";
        if (item.max_discount_price) {
          maxNum = item.max_discount_price[this.couponCurrentCode];
        }
        let str = "";
        if (this.pointPrice) {
          let discountPrice =
            (this.pointPrice * 100 * (100 - item.discount)) / 100 / 100;
          if (this.couponCurrentCode !== "USD") {
            discountPrice = discountPrice.toFixed();
          } else {
            discountPrice = discountPrice.toFixed(2);
          }
          if (maxNum && discountPrice > maxNum) {
            str = maxNum;
          } else {
            str = discountPrice;
          }
        } else {
          if (this.chooseProduct.price) {
            let discountPrice =
              (this.chooseProduct.price * (100 - item.discount)) / 100 / 100;
            if (this.couponCurrentCode !== "USD") {
              // discountPrice = Math.ceil(discountPrice);
            } else {
              discountPrice = discountPrice.toFixed(2);
            }
            if (maxNum && discountPrice > maxNum) {
              str = maxNum;
            } else {
              str = discountPrice;
            }
          }
        }
        price = Number(price) + Number(str);
      });
      if (this.couponCurrentCode !== "USD") {
        price = Math.ceil(price);
      }
      if (this.pointPrice) {
        price >
        this.pointPrice - this.minPrices[this.pointCustom.currency_code_city] / 100
          ? (price =
              this.pointPrice -
              this.minPrices[this.pointCustom.currency_code_city] / 100)
          : "";
      } else if (this.chooseProduct.price) {
        price >
        (this.chooseProduct.price -
          this.minPrices[this.chooseProduct.currency_code_city]) /
          100
          ? (price =
              (this.chooseProduct.price -
                this.minPrices[this.chooseProduct.currency_code_city]) /
              100)
          : "";
      }
      return price;
    },
    isPad() {
      const ua = window.navigator.userAgent;
      let IsIPad = false;
      if (/ipad/i.test(ua)) {
        IsIPad = true;
      }
      // iPad from IOS13
      const macApp = ua.match(/Macintosh/i) != null;
      if (macApp) {
        // need to distinguish between Macbook and iPad
        const canvas = document.createElement("canvas");
        if (canvas != null) {
          const context =
            canvas.getContext("webgl") ||
            canvas.getContext("experimental-webgl");
          if (context) {
            const info = context.getExtension("WEBGL_debug_renderer_info");
            if (info) {
              const renderer = context.getParameter(
                info.UNMASKED_RENDERER_WEBGL
              );
              if (renderer.indexOf("Apple") != -1) IsIPad = true;
            }
          }
        }
      }
      return IsIPad;
    },
    couponIds() {
      let str = "";
      this.hasChooseCouponList.forEach((item) => {
        str = str + "," + item.id;
      });
      str = str.slice(1);
      return str;
    },
  },
  data() {
    return {
      payWayId: 0,
      payWay: "mycard1",
      giftList: [],
      pointList: [],
      showLoading: false,
      message: "",
      roleId: this.$route.query.roleId,
      chooseProduct: {},
      projectId: getLocalStorage("projectId"),
      cardId: "",
      cardPw: "",
      gashData: {},
      gashUrl: "",
      payWays: [],
      timer: null,
      needFix: false,
      isEn: this.$isEn,
      isKr: this.$isKr,
      isTw: this.$isTw,
      showPayStatus: false,
      out_trade_no: "",
      payStatus: -99,
      pg_code: "naverpay",
      payLetterList: [
        {
          name: "naver pay",
          id: "naverpay",
          img: require("@/assets/images/mycard/naverpay.png"),
        },
        {
          name: "payco",
          id: "payco",
          img: require("@/assets/images/mycard/payco.png"),
        },
        {
          name: "kakao pay",
          id: "kakaopay",
          img: require("@/assets/images/mycard/opay.png"),
        },
        {
          name: "信用卡",
          id: "creditcard",
          img: require("@/assets/images/mycard/crad_icon.png"),
        },
      ],
      showAds: !getLocalStorage("hasShowAds"),
      adPopup: "",
      adsList: [],
      showPoints: false,
      pointNum: "",
      pointPrice: "",
      pointExtraNum: "",
      pointError: false,
      pointCustom: {
        addition_num: "",
        currency_code: "",
        exchange_rate: "",
        max_num: "",
        min_num: "",
        pay_point: "",
        currency_code_city: ""
      },
      couponList: [],
      showInfo: false,
      chooseQuestion: {},
      hasChooseCouponList: [],
      showCouponModal: false,
      couponOrder: {},
      couponCurrentCode: "",
      showExchange: false,
      minPrices: {
        CNY: 1,
        KRW: 100,
        TWD: 100,
        JPY: 100,
        HKD: 100,
        USD: 1,
      },
    };
  },
  created() {
    this.showLoading = false;
    // this.myCardItemList();
    // if (this.btnDisabled && getLocalStorage("projectId")) {
    this.chooseProduct = getLocalStorage("chooseProduct")
      ? JSON.parse(getLocalStorage("chooseProduct"))
      : {};
    this.pointPrice = Number(getLocalStorage("pointPrice")) || 0;
    this.pointNum = Number(getLocalStorage("pointNum")) || 0;
    this.pointExtraNum = Number(getLocalStorage("pointExtraNum")) || 0;
    setTimeout(() => {
      this.showPayBtns();
      if (getLocalStorage("projectId")) {
        this.webItemList();
      }
    }, 300);
    if (this.chooseProduct.currency_code === "₩") {
      this.chooseProduct.currency_code_city = "KRW";
    } else {
      this.chooseProduct.currency_code_city = this.chooseProduct.currency_code;
    }
  },
  mounted() {
    this.watchProjectId();
    setTimeout(() => {
      window.addEventListener("scroll", this.scrollToTop);
      this.initSwiper();
    }, 500);
  },
  watch: {
    chooseRoleInfo(val) {
      if (val) {
        this.webItemList();
      }
    },
    payWay(val) {
      if (val) {
        this.webItemList();
      }
    },
    couponCurrentCode(val) {
      if (val) {
        if (!this.btnDisabled) {
          this.myCoupon();
        }
      }
    },
    hasChooseCouponList(val) {
      if (val) {
        let notShare = false;
        this.couponList.forEach((each) => {
          let flag = false;
          val.forEach((item) => {
            if (item.id === each.id) {
              flag = true;
              item.can_share !== 1 ? (notShare = true) : "";
            }
          });
          flag ? (each.active = 1) : (each.active = 0);
        });
        this.setCouponDisabled();
        this.couponList.forEach((each) => {
          if (val.length === 2 && each.active !== 1) {
            each.disabled = true;
          } else {
            if (notShare) {
              if (each.can_share !== 1 && each.active !== 1) {
                each.disabled = true;
              }
            }
          }
        });
        this.$forceUpdate();
      }
    },
  },
  methods: {
    couponExchange() {
      if (this.btnDisabled) {
        this.showLogin();
      } else {
        this.showExchange = true;
      }
    },
    chooseCoupon(item) {
      let flag = false;
      this.hasChooseCouponList.forEach((each, index) => {
        if (each.id === item.id) {
          flag = true;
          this.hasChooseCouponList.splice(index, 1);
        }
      });
      if (!item.disabled) {
        if (!flag && this.hasChooseCouponList.length < 2) {
          this.hasChooseCouponList.push(item);
        }
      }
    },
    showCouponInfo(item) {
      item.content = item.content.replace(/\n/g, "</br>");
      this.chooseQuestion = item;
      this.showInfo = true;
    },
    formatDate(dateNum, fmt) {
      fmt ? "" : (fmt = "yyyy-MM-dd hh:mm:ss");
      const date = new Date(Number(dateNum) * 1000);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    myCoupon() {
      this.couponList = [];
      myCoupon().then((res) => {
        this.couponList = res.data.coupons;
        this.couponList.forEach((item) => {
          item.active = 0;
        });
        this.setCouponDisabled();
        this.hasChooseCouponList = [];
      });
    },
    hasCustom(list) {
      let flag = false;
      list.forEach((item) => {
        if (item.productType === 80) {
          flag = true;
        }
      });
      return flag;
    },
    showLogin() {
      this.$parent.showLogin = true;
    },
    initSwiper() {
      const mySwiper = new Swiper(".swiper", {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            // alert(this.activeIndex);
          },
        },
      });
    },
    closeAds() {
      this.showAds = false;
      setLocalStorage("hasShowAds", true);
    },
    showPayBtns() {
      this.payWays = JSON.parse(getSessionStorage("configInit")).pay_ways;
      this.payWays.forEach((item) => {
        if (item.type === "15") {
          this.payWayId = item.type;
          this.payWay = "paypal";
        } else if (item.type === "12") {
          this.payWayId = item.type;
          this.payWay = "gash1";
        } else if (item.type === "11") {
          this.payWayId = item.type;
          this.payWay = "mycard1";
        }
      });
      const arr = [];
      this.payWays.forEach((item) => {
        if (item.type === "12") {
          item.name = "gash1";
          item.text = this.$t("myCard.payWay1");
          arr.push(item);
          arr.push({
            name: "gash2",
            type: "12",
            text: this.$t("myCard.payWay2"),
          });
        } else if (item.type === "11") {
          item.name = "mycard1";
          item.text = this.$t("myCard.payWay3");
          arr.push(item);
          arr.push({
            name: "mycard2",
            type: "11",
            text: this.$t("myCard.payWay4"),
          });
        } else if (item.type === "15") {
          item.name = "paypal";
          arr.push(item);
        } else if (item.type === "22") {
          item.name = "payletter";
          arr.push(item);
        } else if (item.type === "18") {
          item.name = "payPalCard";
          arr.push(item);
        }
      });
      this.payWays = arr;
      if (this.payWays.length > 0) {
        this.payWayId = this.payWays[0].type;
        this.payWay = this.payWays[0].name;
      }
    },
    choosePayWay(item) {
      this.payWay = item.name;
      this.payWayId = item.type;
      this.cardId = "";
      this.cardPw = "";
    },
    watchProjectId() {
      const _this = this;
      window.addEventListener("setItemEvent", function (e) {
        if (e.key === "projectId") {
          _this.webItemList(e.newValue);
        }
      });
    },
    choosePay(item) {
      this.pay = item.id;
      this.webItemList();
    },
    chooseGift(item) {
      if (item.show !== 0) {
        this.chooseProduct = Object.assign({}, item);
        if (this.chooseProduct.currency_code === "₩") {
          this.chooseProduct.currency_code_city = "KRW";
        } else {
          this.chooseProduct.currency_code_city = this.chooseProduct.currency_code;
        }
        setLocalStorage("chooseProduct", JSON.stringify(this.chooseProduct));
        this.closePointModal();
        this.setCouponDisabled();
        this.hasChooseCouponList = [];
      }
    },
    setCouponDisabled() {
      this.couponList.forEach((coupon) => {

        if (this.pointPrice) {
          // 有最低使用门槛
          if (
            coupon.price_condition &&
            coupon.price_condition[this.couponCurrentCode]
          ) {
            if (
              this.pointPrice <
              Number(coupon.price_condition[this.couponCurrentCode])
            ) {
              coupon.disabled = true;
            } else {
              coupon.disabled = false;
            }
          } else {
            coupon.disabled = false;
          }
        } else {
          // 是虹晶
          if (this.chooseProduct.productType && this.chooseProduct.productType != "80" && coupon.type === 1) {
            coupon.disabled = true;
          } else {
            // 有最低使用门槛
            if (
              coupon.price_condition &&
              coupon.price_condition[this.couponCurrentCode]
            ) {

              if (
                this.chooseProduct.price &&
                this.chooseProduct.price / 100 <
                  Number(coupon.price_condition[this.couponCurrentCode])
              ) {
                coupon.disabled = true;
              } else {
                coupon.disabled = false;
              }
            } else {
              coupon.disabled = false;
            }
          }
        }
      });
    },
    setPoint() {
      this.showPoints = true;
    },
    closePointModal() {
      this.showPoints = false;
      this.pointNum = "";
      this.pointPrice = "";
      this.pointExtraNum = "";
      this.pointError = false;
      setLocalStorage("pointPrice", "");
      setLocalStorage("pointExtraNum", "");
      setLocalStorage("pointExtraNum", "");
    },
    inputPoint() {
      if (
        this.pointNum < this.pointCustom.min_num ||
        this.pointNum == this.pointCustom.min_num ||
        this.pointNum > this.pointCustom.max_num ||
        this.pointNum % 10 !== 0
      ) {
        this.pointError = true;
      } else {
        this.pointError = false;
      }
    },
    submitPoint() {
      if (this.pointError) {
        return;
      }
      this.pointPrice = Math.floor(
        this.pointNum * this.pointCustom.exchange_rate
      );
      this.pointExtraNum = Math.ceil(
        this.pointNum * this.pointCustom.addition_num
      );
      setLocalStorage("pointPrice", this.pointPrice);
      setLocalStorage("pointNum", this.pointNum);
      setLocalStorage("pointExtraNum", this.pointExtraNum);
      setLocalStorage("chooseProduct", "");
      this.showPoints = false;
      this.setCouponDisabled()
    },
    webItemList(newId) {
      if (
        this.payWayId === 0 ||
        (!this.btnDisabled && !this.chooseRoleInfo.role_id)
      ) {
        return;
      }
      let project_id = "";
      if (newId) {
        project_id = newId;
      } else {
        if (getLocalStorage("projectId")) {
          project_id = getLocalStorage("projectId");
        } else {
          project_id = this.chooseRoleInfo.project_id;
        }
      }
      this.showLoading = true;
      webItemList({
        // game_app_id: this.gameAppId,
        role_id:
          this.chooseRoleInfo && this.chooseRoleInfo.role_id
            ? this.chooseRoleInfo.role_id
            : "",
        project_id: project_id,
        payway_id: this.payWayId,
      })
        .then((res) => {
          if (res.data.ad.image) {
            this.adPopup = res.data.ad.adPopup;
            let lang = "ko-kr";
            if (this.isKr) {
              lang = "ko-kr";
            } else if (this.isEn) {
              lang = "en-us";
            } else {
              lang = "zh-tw";
            }
            if (res.data.ad.image[lang] && res.data.ad.image[lang].length) {
              this.adsList = res.data.ad.image[lang];
            } else {
              this.showAds = false;
            }
          } else {
            this.showAds = false;
          }
          if (res.data.custom != null) {
            this.pointCustom = res.data.custom;
            this.pointCustom.currency_code_city = this.pointCustom.currency_code;
            if (
              this.pointCustom.currency_code &&
              this.pointCustom.currency_code === "KRW"
            ) {
              this.pointCustom.currency_code = "₩";
            }
          }
          this.couponCurrentCode = res.data.currency_code || "";
          this.giftList = [];
          const titleArr = [
            this.$t("myCard.secTitle1"),
            this.$t("myCard.secTitle2"),
            this.$t("myCard.secTitle3"),
            this.$t("myCard.secTitle4"),
          ];
          Object.values(res.data.common).forEach((list, index) => {
            let obj = {
              title: titleArr[index],
              arr: [],
            };
            list.forEach((inner) => {
              inner.products.forEach((item) => {
                item.productType = inner.productType;
                if (item.currency_code && item.currency_code === "KRW") {
                  item.currency_code_city = item.currency_code;
                  item.currency_code = "₩";
                }
                if (item.expiration_time) {
                  if (item.expiration_time > 0) {
                    if (
                      item.current_stock &&
                      item.current_stock < 1 &&
                      item.current_stock !== -1
                    ) {
                      item.current_stock = "0";
                      item.show = 0;
                    } else {
                      item.show = 1;
                    }
                    item.hours = Math.floor(item.expiration_time / 3600);
                    item.minute = Math.floor(
                      Math.floor(item.expiration_time % 3600) / 60
                    );
                    item.second = item.expiration_time % 60;
                  } else {
                    item.show = 0;
                  }
                } else {
                  item.show = 1;
                }
                // if (item.product_subject === "" || !item.product_subject) {
                // if (!this.isKr) {
                staticGiftList.forEach((gift) => {
                  if (gift.product_id == item.product_id) {
                    if (this.isEn) {
                      item.product_subject = gift.product_subject_en;
                    } else if (this.isKr) {
                      item.product_subject = gift.product_subject_kr;
                    } else {
                      item.product_subject = gift.product_subject;
                    }
                    if (!gift.icon) {
                      item.icon = gift.product_id;
                    }
                  }
                });
                // }
                // productType = 305 or 306 名字写死
                if (item.productType === 305) {
                  item.product_subject = "限時秒殺禮包";
                } else if (item.productType === 306) {
                  item.product_subject = "金喵賜福禮包";
                }
                // }
              });
              obj.arr.push(...inner.products);
            });
            this.giftList.push(obj);
          });
          setTimeout(() => {
            this.countDown();
          }, 100);
          this.pointList = res.data.point;
          if (this.isPad) {
            this.queryCouponOrder();
          }
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    payQuery() {
      payQuery({ out_trade_no: this.out_trade_no }).then((res) => {
        this.payStatus = res.data.status || 0;
        if (this.payStatus === 2 || this.payStatus === 4) {
          this.webItemList();
          this.addPayTime();
          this.myCoupon();
          this.closeCouponModal();
          this.chooseProduct = {};
          this.cardId = "";
          this.cardPw = "";
        }
      });
    },
    addPayTime() {
      let arr = getLocalStorage("payTime")
        ? JSON.parse(getLocalStorage("payTime"))
        : "" || [];
      let flag = true;
      arr.forEach((item) => {
        if (
          item.open_id === JSON.parse(getLocalStorage("accountStorage")).open_id
        ) {
          item.payTime = Date.parse(new Date());
          item.role_id = this.chooseRoleInfo.role_id;
          flag = false;
        }
      });
      if (flag) {
        let obj = {
          open_id: JSON.parse(getLocalStorage("accountStorage")).open_id,
          payTime: Date.parse(new Date()),
          role_id: this.chooseRoleInfo.role_id,
        };
        arr.push(obj);
      }
      setLocalStorage("payTime", JSON.stringify(arr));
    },
    closeStatus() {
      this.webItemList();
      this.myCoupon();
      this.showPayStatus = false;
      this.payStatus = -99;
    },
    closeCouponModal() {
      this.showCouponModal = false;
      this.queryCouponOrder();
    },
    closeExchangeModal() {
      this.showExchange = false;
      this.myCoupon();
    },
    submit() {
      if (this.isPad) {
        this.submitPad();
      } else {
        if (!this.disabled) {
          this.couponOrder = {};
          this.showLoading = true;
          queryCouponOrder()
            .then((res) => {
              this.showLoading = false;
              if (
                res.data.trade.project_id &&
                res.data.trade.project_id == getLocalStorage("projectId")
              ) {
                this.couponOrder = res.data.trade;
                this.showCouponModal = true;
                if (this.couponOrder.product_id == 99999999) {
                  this.couponOrder.product_subject =
                    this.$t("myCard.extraTitle");
                } else {
                  this.giftList &&
                    this.giftList.forEach((item) => {
                      item.arr.forEach((each) => {
                        if (each.product_id == this.couponOrder.product_id) {
                          this.couponOrder.product_subject =
                            each.product_subject;
                        }
                      });
                    });
                }
              } else {
                this.showLoading = true;
                let obj = {
                  product_id: this.chooseProduct.product_id,
                  product_subject: this.chooseProduct.product_subject,
                  amount: this.chooseProduct.price,
                  game_app_id: this.chooseRoleInfo.game_app_id,
                  // trade_type: "2",
                  // payment_type: "COSTPOINT",
                  server_id: this.chooseRoleInfo.server_id,
                  role_id: this.chooseRoleInfo.role_id,
                  success_url: window.location.origin + "/mycard/final?final=1",
                  failed_url:
                    window.location.origin + "/mycard/final?final=0&msg=%s",
                  user_coupon_id: this.couponIds,
                  mobile: 0, // 0:PC 1:H5
                };

                if (this.payWay === "gash1") {
                  gashCommonOrder(obj)
                    .then((res) => {
                      this.showLoading = false;
                      this.showPayStatus = true;
                      if (res.data.transaction_url) {
                        this.out_trade_no = res.data.out_trade_no;
                        this.gashUrl = res.data.transaction_url;
                        this.gashData = res.data.auth_code;
                        let formData = `<form name="form1" id="form1" action=${this.gashUrl} method="post">
                  <input type="hidden" name="data" value=${this.gashData}>
                  <input type="submit" value="send">
                </form>`;

                        let newWindow = window.open("", "catPay");
                        newWindow.document.write("<html><head></head><body>");
                        let div = newWindow.document.createElement("div");
                        div.innerHTML = formData;
                        newWindow.document.body.appendChild(div);
                        div.style.display = "none";
                        newWindow.document.form1.submit();
                      } else {
                        this.showLoading = false;
                        this.message = this.$t("myCard.failed");
                      }
                    })
                    .catch((error) => {
                      this.showLoading = false;
                      this.message = error.message;
                    });
                } else if (this.payWay === "paypal") {
                  if (this.pointNum) {
                    let payPalObj = Object.assign({}, obj);
                    payPalObj.amount = this.pointNum;
                    payPalObj.product_id = 99999999;
                    payPalObj.product_subject = this.$t("myCard.extraTitle");
                    payPalCustom(payPalObj)
                      .then((res) => {
                        this.showLoading = false;
                        this.showPayStatus = true;
                        if (res.data.transaction_url) {
                          this.out_trade_no = res.data.out_trade_no;
                          // window.location.href = res.data.transaction_url;
                          window.open(res.data.transaction_url, "catPay");
                        } else {
                          this.showLoading = false;
                          this.message = this.$t("myCard.failed");
                        }
                      })
                      .catch((error) => {
                        this.showLoading = false;
                        this.message = error.message;
                      });
                  } else {
                    payPalWebOrder(obj)
                      .then((res) => {
                        this.showLoading = false;
                        this.showPayStatus = true;
                        if (res.data.transaction_url) {
                          this.out_trade_no = res.data.out_trade_no;
                          // window.location.href = res.data.transaction_url;
                          window.open(res.data.transaction_url, "catPay");
                        } else {
                          this.showLoading = false;
                          this.message = this.$t("myCard.failed");
                        }
                      })
                      .catch((error) => {
                        this.showLoading = false;
                        this.message = error.message;
                      });
                  }
                } else if (this.payWay === "payletter") {
                  const payLetterObj = Object.assign(
                    { pg_code: this.pg_code },
                    obj
                  );
                  if (this.pointNum) {
                    payLetterObj.product_id = 99999999;
                    payLetterObj.product_subject = this.$t("myCard.extraTitle");
                    payLetterObj.amount = this.pointNum;
                    payLetterCustom(payLetterObj)
                      .then((res) => {
                        this.showLoading = false;
                        this.showPayStatus = true;
                        if (res.data.transaction_url) {
                          this.out_trade_no = res.data.out_trade_no;
                          // window.location.href = res.data.transaction_url;
                          window.open(res.data.transaction_url, "catPay");
                        } else {
                          this.showLoading = false;
                          this.message = this.$t("myCard.failed");
                        }
                      })
                      .catch((error) => {
                        this.showLoading = false;
                        this.message = error.message;
                      });
                  } else {
                    payLetterOrder(payLetterObj)
                      .then((res) => {
                        this.showLoading = false;
                        this.showPayStatus = true;
                        if (res.data.transaction_url) {
                          this.out_trade_no = res.data.out_trade_no;
                          // window.location.href = res.data.transaction_url;
                          window.open(res.data.transaction_url, "catPay");
                        } else {
                          this.showLoading = false;
                          this.message = this.$t("myCard.failed");
                        }
                      })
                      .catch((error) => {
                        this.showLoading = false;
                        this.message = error.message;
                      });
                  }
                } else {
                  myCardWebOrder(obj)
                    .then((res) => {
                      this.showLoading = false;
                      this.showPayStatus = true;
                      if (res.data.transaction_url) {
                        this.out_trade_no = res.data.out_trade_no;
                        // window.location.href = res.data.transaction_url;
                        window.open(res.data.transaction_url, "catPay");
                      } else {
                        this.showLoading = false;
                        this.message = this.$t("myCard.failed");
                      }
                    })
                    .catch((error) => {
                      this.showLoading = false;
                      this.message = error.message;
                    });
                }
              }
            })
            .catch((error) => {
              this.showLoading = false;
              this.message = error.message;
            });
        }
      }
    },
    queryCouponOrder() {
      this.showLoading = true;
      queryCouponOrder()
        .then((res) => {
          this.showLoading = false;
          this.couponOrder = {};
          if (
            res.data.trade.project_id &&
            res.data.trade.project_id == getLocalStorage("projectId")
          ) {
            this.couponOrder = res.data.trade;
            if (this.couponOrder.product_id == 99999999) {
              this.couponOrder.product_subject = this.$t("myCard.extraTitle");
            } else {
              this.giftList &&
                this.giftList.forEach((item) => {
                  item.arr.forEach((each) => {
                    if (each.product_id == this.couponOrder.product_id) {
                      this.couponOrder.product_subject = each.product_subject;
                    }
                  });
                });
            }
          }
        })
        .catch((error) => {
          this.showLoading = false;
          this.message = error.message;
        });
    },
    submitPad() {
      if (!this.disabled) {
        if (this.couponOrder.product_id) {
          this.showCouponModal = true;
        } else {
          this.showLoading = true;
          let obj = {
            product_id: this.chooseProduct.product_id,
            product_subject: this.chooseProduct.product_subject,
            amount: this.chooseProduct.price,
            game_app_id: this.chooseRoleInfo.game_app_id,
            // trade_type: "2",
            // payment_type: "COSTPOINT",
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            user_coupon_id: this.couponIds,
            mobile: 0, // 0:PC 1:H5
          };
          let url = window.location.origin + "/loading";
          let winOpen = window.open(url, "catPay");
          if (this.payWay === "gash1") {
            gashCommonOrder(obj)
              .then((res) => {
                this.showLoading = false;
                this.showPayStatus = true;
                if (res.data.transaction_url) {
                  this.out_trade_no = res.data.out_trade_no;
                  this.gashUrl = res.data.transaction_url;
                  this.gashData = res.data.auth_code;
                  let formData = `<form name="form1" id="form1" action=${this.gashUrl} method="post">
                  <input type="hidden" name="data" value=${this.gashData}>
                  <input type="submit" value="send">
                </form>`;

                  let newWindow = window.open("", "catPay");
                  newWindow.document.write("<html><head></head><body>");
                  let div = newWindow.document.createElement("div");
                  div.innerHTML = formData;
                  newWindow.document.body.appendChild(div);
                  div.style.display = "none";
                  newWindow.document.form1.submit();
                } else {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = this.$t("myCard.failed");
                }
              })
              .catch((error) => {
                winOpen.close();
                this.showLoading = false;
                this.message = error.message;
              });
          } else if (this.payWay === "paypal") {
            if (this.pointNum) {
              let payPalObj = Object.assign({}, obj);
              payPalObj.amount = this.pointNum;
              payPalObj.product_id = 99999999;
              payPalObj.product_subject = this.$t("myCard.extraTitle");
              payPalCustom(payPalObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            } else {
              payPalWebOrder(obj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            }
          } else if (this.payWay === "payletter") {
            const payLetterObj = Object.assign({ pg_code: this.pg_code }, obj);
            if (this.pointNum) {
              payLetterObj.product_id = 99999999;
              payLetterObj.product_subject = this.$t("myCard.extraTitle");
              payLetterObj.amount = this.pointNum;
              payLetterCustom(payLetterObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            } else {
              payLetterOrder(payLetterObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            }
          } else {
            myCardWebOrder(obj)
              .then((res) => {
                this.showLoading = false;
                this.showPayStatus = true;
                if (res.data.transaction_url) {
                  this.out_trade_no = res.data.out_trade_no;
                  // window.location.href = res.data.transaction_url;
                  setTimeout(() => {
                    winOpen.location = res.data.transaction_url;
                  }, 200);
                } else {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = this.$t("myCard.failed");
                }
              })
              .catch((error) => {
                winOpen.close();
                this.showLoading = false;
                this.message = error.message;
              });
          }
        }
      }
    },
    submitCard() {
      if (!this.disabled) {
        this.showLoading = true;
        if (this.payWay === "gash2") {
          let obj1 = {
            // access_token: "0000018704763bd179ee6e98065859813",
            // app_id: "05eb00895c7cdda96dc3974300fc03cc",
            game_app_id: this.chooseRoleInfo.game_app_id,
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            user_coupon_id: this.couponIds,
            mobile: 0, // 0:PC 1:H5
          };
          gashPointOrder(obj1)
            .then((res) => {
              this.showLoading = false;
              this.showPayStatus = true;
              if (res.data.transaction_url) {
                this.gashUrl = res.data.transaction_url;
                this.gashData = res.data.auth_code;
                let formData = `<form name="form1" id="form1" action=${this.gashUrl} method="post">
              <input type="hidden" name="data" value=${this.gashData}>
              <input type="submit" value="send">
            </form>`;

                let newWindow = window.open("", "catPay");
                newWindow.document.write("<html><head></head><body>");
                let div = newWindow.document.createElement("div");
                div.innerHTML = formData;
                newWindow.document.body.appendChild(div);
                div.style.display = "none";
                newWindow.document.form1.submit();
              } else {
                this.showLoading = false;
                this.message = this.$t("myCard.failed");
              }
            })
            .catch((error) => {
              this.showLoading = false;
              this.message = error.message;
            });
        } else {
          let obj = {
            card_id: this.cardId,
            card_pw: this.cardPw,
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            game_app_id: this.chooseRoleInfo.game_app_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            user_coupon_id: this.couponIds,
            mobile: 0, // 0:PC 1:H5
          };
          if (this.cardPw.length > 0 && this.cardId.length > 0) {
            myCardInGamePay(obj)
              .then((res) => {
                this.showPayStatus = true;
                if (res.data.success === 1) {
                  this.payStatus = 2;
                } else {
                  this.payStatus = 5;
                }
                this.showLoading = false;
                let href =
                  window.location.origin +
                  "/mycard/final?final=" +
                  res.data.success +
                  "&msg=" +
                  res.data.msg;
                window.open(href, "catPay");
                // this.$router.push({
                //   path: "/mycard/final",
                //   query: { final: res.data.success, msg: res.data.msg },
                // });
              })
              .catch((error) => {
                this.showLoading = false;
                this.message = error.message;
              });
          } else {
            this.showLoading = false;
          }
        }
      }
    },
    formatTime(item) {
      let hoursStr = "";
      let minuteStr = "";
      let secondStr = "";
      hoursStr =
        item.hours.toString().length === 1 ? `0${item.hours}` : item.hours;
      minuteStr =
        item.minute.toString().length === 1 ? `0${item.minute}` : item.minute;
      secondStr =
        item.second.toString().length === 1 ? `0${item.second}` : item.second;
      if (item.hours / 24 > 2) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else if (item.hours / 24 > 1) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else {
        return hoursStr + ":" + minuteStr + ":" + secondStr;
      }
    },
    countDown() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.giftList.forEach((list) => {
          list.arr.forEach((item) => {
            if (item.expiration_time && item.expiration_time > 0) {
              if (item.hours === 0) {
                if (item.minute !== 0 && item.second === 0) {
                  item.second = 59;
                  item.minute -= 1;
                } else if (item.minute === 0 && item.second === 0) {
                  item.second = 0;
                  item.show = 0;
                  clearInterval(this.timer);
                  if (this.chooseProduct.show === 0) {
                    this.chooseProduct = {};
                  }
                } else {
                  item.second -= 1;
                }
              } else {
                if (item.minute !== 0 && item.second === 0) {
                  item.second = 59;
                  item.minute -= 1;
                } else if (item.minute === 0 && item.second === 0) {
                  item.hours -= 1;
                  item.minute = 59;
                  item.second = 59;
                } else {
                  item.second -= 1;
                }
              }
              this.$forceUpdate();
            }
          });
        });
      }, 1000);
    },
  },
};
</script>
